.section-wrapper {
  margin-bottom: 30px;
  &.centered-section {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    max-width: $max-width;
  }
  .section-title {
    align-items: center;
    display: flex;
    flex-direction: row;
    text-transform: uppercase;
    i {
      margin-right: 12px;
    }
  }
  &.highlighted-section {
    margin-bottom: 20px;
  }
}

.highlighted-section {
  border: 2px solid $night-laino;
  margin-bottom: 20px;
  padding: 20px 30px;
  .highlighted-text {
    color: $opacity-8;
    padding: 0 10px;
    font-size: $font-size-lg;
    &.upper {
      text-transform: uppercase;
    }
    &.info {
      color: $info;
    }
    &.warning {
      color: $warning;
    }
    &.success {
      color: $success;
    }
    &.danger {
      color: $danger;
    }
  }
  &.info {
    border-color: $info;
    .highlighted-text,
    i {
      color: $info;
    }
  }
  &.warning {
    border-color: $warning;
    .highlighted-text,
    i {
      color: $warning;
    }
  }
  &.success {
    border-color: $success;
    .highlighted-text,
    i {
      color: $success;
    }
  }
  &.danger {
    border-color: $danger;
    .highlighted-text,
    i {
      color: $danger;
    }
  }

  .highlighted-sub-section {
    padding: 24px 0 24px 0;
    h5 {
      color: rgba($white, 0.6);
      text-transform: uppercase;
    }
    .wrapper-subsection-control {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .icon-copy {
        font-size: 30px;
        margin: 2rem 1rem 1rem 0.31rem;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.context-root,
.context-info,
.context-info + .context-state {
  margin: 0 12px;
}

.context-info {
  font-size: $font-size-xl;
}
.context-state {
  border: 2px solid $night-laino;
  border-radius: 5px;
  //color: $white;
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
  letter-spacing: 0.07rem;
  padding: 6px;
  &.info {
    border-color: $info;
    color: $info;
  }
  &.warning {
    border-color: $warning;
    color: $warning;
    //color: $black-states;
  }
  &.success {
    border-color: $success;
    color: $success;
  }
  &.error {
    border-color: $danger;
    color: $danger;
  }
}

.section-title,
.card {
  margin: 0 auto;
  max-width: $card-max-width;
}

.section-intro {
  font-size: 20px;
  margin-bottom: 15px;
  line-height: 1.5rem;
}

.form-section {
  margin-bottom: 2.5rem;
}

.subsection-title {
  color: $white;
  margin: 15px 0;
}

.overview-wrapper {
  margin-bottom: 30px;
  .setup-step {
    display: flex;
    flex-direction: column;
    .step-number,
    .step-name {
      margin: 0 auto;
      text-align: center;
      &.info {
        border-color: $info;
        color: $info;
      }
      &.success {
        border-color: $success;
        color: $success;
      }
      &.error {
        border-color: $danger;
        color: $danger;
      }
    }
    .step-number {
      border: 2px solid $warning;
      border-radius: 50%;
      color: $warning;
      height: 50px;
      font-size: 30px;
      line-height: 50px;
      width: 50px;
    }
    .step-name {
      color: $warning;
      margin-top: 5px;
      text-transform: uppercase;
    }
  }
  .setup-separator {
    border-bottom: 5px solid rgba(255, 255, 255, 0.7);
    text-align: center;
  }
}

//Kubernetes settings section
.settings-section {
  margin: 0 0 20px 0;

  // Sub section
  &.sub-section {
    margin: 0 40px;
    padding: 20px;

    .highlighted-section {
      .section-submit-wrapper {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0;
      }
    }

    &.highlighted-section {
      padding: 0 20px;

      .section-submit-wrapper {
        margin-bottom: 24px;
      }
    }

    .elem-submit-btn {
      margin: 15px 0;
    }

    .form-group {
      margin: 0;
    }
    .sub-section-header {
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);

      .sub-section-title {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: baseline;

        i {
          font-size: 17px;
          margin-right: 8px;
        }
        .software-version {
          margin: 0 12px 0 12px;
          min-width: 60px; //74px for v99.99.99 length
          font-size: 0.7500000025rem;
        }
      }
      .sub-section-btn {
        text-align: right;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .sub-section-body {
      margin-top: 30px;

      &.storage-sub-section {
        label {
          text-transform: uppercase;
        }
        .form-group {
          margin-bottom: 15px;
        }
      }

      .subsection-child-header {
        margin-top: 30px;
      }
    }
    .sub-section-footer {
      margin-top: 20px;
    }
  }

  &.app-component {
    margin: 20px 40px;
  }

  &.sub-section-first {
    padding: 0 20px 60px 20px;
  }
}

//Certificate expiration info
.cert-info-wrapper {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  white-space: pre-wrap;

  i {
    position: absolute;
    top: 22px;
    left: 14px;
  }

  .cert-info-date,
  .cert-info-message {
    padding-left: 25px;
  }

  &.success {
    i,
    .cert-info-message {
      color: $info;
    }
  }
  &.warning {
    i,
    .cert-info-message {
      color: $warning;
    }
    .cert-info-message {
      font-weight: bold;
    }
  }
  &.danger {
    i,
    .cert-info-message {
      color: $danger;
    }
    .cert-info-message {
      font-weight: bold;
    }
  }
}

[data="primary"] {
  .info-wrapper.btn {
    &:hover,
    &:active,
    &:active:focus {
      color: $primary !important;
    }
    &:hover {
      .info-icon {
        color: $primary;
      }
    }
  }

  .sub-section-header {
    .sub-section-title {
      &.expanded {
        color: $primary;
        h4 {
          color: $primary;
        }
      }
    }
  }

  .cluster-icon {
    &:hover {
      i {
        border-color: $primary;
        color: $primary;
      }
    }
  }
  .app-status-info {
    &:hover {
      color: $primary;
    }
  }
  .settings-conf-list {
    .component-config-wrapper {
      .settings-conf-elem {
        &:hover {
          border-color: $primary;
        }
      }
    }
  }
}
[data="second"] {
  .info-wrapper.btn {
    &:hover,
    &:active,
    &:active:focus {
      color: $second !important;
    }
    &:hover {
      .info-icon {
        color: $second;
      }
    }
  }

  .sub-section-header {
    .sub-section-title {
      &.expanded {
        color: $second;
        h4 {
          color: $second;
        }
      }
    }
  }

  .cluster-icon {
    &:hover {
      i {
        border-color: $second;
        color: $second;
      }
    }
  }
  .app-status-info {
    &:hover {
      color: $second;
    }
  }
  .settings-conf-list {
    .component-config-wrapper {
      .settings-conf-elem {
        &:hover {
          border-color: $second;
        }
      }
    }
  }
}
[data="third"] {
  .info-wrapper.btn {
    &:hover,
    &:active,
    &:active:focus {
      color: $third !important;
    }
    &:hover {
      .info-icon {
        color: $third;
      }
    }
  }

  .sub-section-header {
    .sub-section-title {
      &.expanded {
        color: $third;
        h4 {
          color: $third;
        }
      }
    }
  }

  .cluster-icon {
    &:hover {
      i {
        border-color: $third;
        color: $third;
      }
    }
  }
  .app-status-info {
    &:hover {
      color: $third;
    }
  }
  .settings-conf-list {
    .component-config-wrapper {
      .settings-conf-elem {
        &:hover {
          border-color: $third;
        }
      }
    }
  }
}

//Warning styled button - icon + text
.info-wrapper.btn {
  background: transparent;
  background-color: transparent;
  background-image: none;
  color: $primary-states;
  font-weight: normal;
  padding: 10px 0 0 0;
  text-align: left;
  white-space: initial;

  .icon-info {
    display: inline-block;
    color: $primary-states;
  }

  &.disabled,
  &[disabled] {
    background-color: transparent;
    border-color: transparent;
    color: white;
  }

  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    background: transparent;
    background-color: transparent !important;
    background-image: none !important;
    border: none;
    box-shadow: none !important;
    -webkit-transform: unset;
    transform: unset;
  }
}

// Service Aplication table
.collapsable-section-name {
  margin-left: 12px;
}

// Application table
.app-name {
  font-size: 20px;
  text-transform: uppercase;
}

.btn-action {
  display: inline-block;
}
.addon-status-label {
  white-space: break-spaces;
  .addon-msg-state:not(:first-child) {
    margin-left: 10px;
  }
  .addon-msg-state {
    text-transform: uppercase;
    &.warning {
      color: $warning;
    }
    &.success {
      color: $success;
    }
    &.error {
      color: $danger;
    }
  }
}

.app-status-label,
.addon-status-label,
.app-status-msg {
  color: $gray-300;
  cursor: default;
  display: inline-block;
}

.app-status-label,
.addon-status-label {
  font-weight: initial;
}

.app-status-msg {
  margin: 0 5px;
  .app-status-info {
    color: $danger;
    &:hover {
      cursor: pointer;
    }
  }
  &.app-msg-highlighted,
  &.app-msg-email {
    font-weight: $font-weight-bold;
  }
  &.app-msg-highlighted {
    text-transform: uppercase;
  }
  &.app-msg-email {
    text-transform: lowercase;
  }
}

.switch-control {
  display: inline-block;
}

//Lists
.cluster-list,
.settings-conf-list {
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0 0 0;
  padding: 0;

  .cluster-elem,
  .settings-conf-elem {
    display: flex;
    list-style: none;
  }
}

// Cluster elements
.cluster-list {
  justify-content: center;

  .cluster-elem {
    align-items: center;
    flex-direction: column;
    margin: 15px 10px;

    //disabled tooltips
    .link-disabled + .cluster-name {
      color: #525f7f;
    }

    .cluster-name {
      &:hover {
        cursor: default;
      }
    }
  }
}

.cluster-icon {
  //disabled links
  &.link-disabled i {
    color: #525f7f;
  }

  i {
    color: rgba(255, 255, 255, 0.7);
    font-size: 25px;
  }
}

//Component configuration settings section
.settings-conf-list {
  justify-content: flex-start;

  .component-config-wrapper {
    align-items: center;
    display: flex;
    margin: 8px;

    .settings-conf-elem {
      align-items: baseline;
      flex-direction: inherit;
      margin: 15px 24px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.7);
      padding-bottom: 3px;

      &.info-wrapper.btn {
        border-radius: 0;
        color: rgba(255, 255, 255, 0.7);
        padding: 0 0 1px 0;
        margin: 0 15px 0 8px;
        i {
          margin-left: 12px;
        }
      }
    }
  }
}
