//Modal Main Design options based on Reactstrap's component
.modal-backdrop {
  background: #000 !important;
  &.show {
    opacity: 0.8 !important;
  }
}

// Modals
// Design element Dialogs
// --------------------------------------------------
.mobile-warning-wrapper {
  background-color: white;
  padding: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .mobile-warning-text {
    color: $black-states;
  }
}

.modal-dialog {
  max-width: 1000px;

  .modal-content {
    border: 0;

    // Modal header
    // Top section of the modal w/ title and dismiss

    .modal-title {
      color: $black;
      font-size: 20px;

      i {
        margin-right: 8px;
      }
    }

    // Modal body
    // Where all modal content resides (sibling of .modal-header and .modal-footer)
    .modal-body {
      line-height: 1.9;
      white-space: break-spaces;

      a {
        display: block;
      }

      p,
      input,
      textarea {
        color: $black;
      }

      textarea {
        height: 335px;
        max-height: fit-content;
        resize: auto;
      }

      .modal-body-link {
        display: block;
        margin: 20px 0;
      }

      .modal-extra-label {
        display: block;
        margin: 20px 0 0 0;
      }

      .modal-extra-text {
        background-color: $white;
        color: $night-laino;
        display: inline-block;
        font-weight: bold;
        padding: 10px;
        word-break: break-all;
        height: auto;
        width: 90%;
        &:focus {
          border-color: initial;
        }
      }

      .modal-icon {
        font-size: 35px;
        vertical-align: middle;
        &:hover {
          cursor: pointer;
        }
        &:hover,
        &:active,
        &:focus {
          color: $primary;
        }
      }

      .form-btn-show-password {
        color: $night-laino;
        
        &:hover,
        &:active,
        &:focus,
        &:active:hover,
        &:active:focus {
          color: $night-laino;
        }
      }
    }
    // Footer (for actions)
    .modal-footer {
      border-top: 0;
      -webkit-justify-content: space-between; /* Safari 6.1+ */
      justify-content: space-between;

      button {
        margin: 0;
        padding-left: 16px;
        padding-right: 16px;
        width: auto;

        &.pull-left {
          padding-left: 5px;
          padding-right: 5px;
          position: relative;
          left: -5px;
        }
      }

      .modal-footer-left {
        :first-child {
          margin-right: 15px;
        }
      }
    }
    .modal-body + .modal-footer {
      padding-top: 0;
    }
  }
}
.modal-backdrop {
  background: rgba(0, 0, 0, 0.3);
}

.modal {
  &.modal-default {
    @include modal-colors($white, $black);
  }

  &.modal-primary {
    @include modal-colors($primary, $white);
  }

  &.modal-danger {
    @include modal-colors($danger, $white);
  }

  &.modal-warning {
    @include modal-colors($warning, $white);
  }

  &.modal-success {
    @include modal-colors($success, $white);
  }

  &.modal-info {
    @include modal-colors($info, $white);
  }

  .modal-header .close {
    color: $danger;
    text-shadow: none;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }

  &.modal-black {
    .modal-content {
      background: linear-gradient(to bottom, $black 0%, $black-states 100%);
      color: rgba($white, 0.8);
      .modal-header {
        .modal-title,
        .title {
          color: rgba($white, 0.9);
        }
      }
      .modal-body {
        p {
          color: rgba($white, 0.8);
        }
      }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: $white;
    }
  }
}

.modal-search {
  .modal-dialog {
    margin: 20px auto;
    max-width: 650px;
    input {
      border: none;
      font-size: 17px;
      font-weight: 100;
    }
    span {
      font-size: 35px;
      color: $search-gray;
    }
  }
  .modal-content {
    .modal-header {
      padding: 24px;
    }
  }

  .modal-header .close {
    color: $dark-background;
    top: 30px !important;
  }

  .modal-footer {
    border-top: 2px solid #f9f9f9;
    margin: 0px 25px 20px;
  }
}
