.table,
table {
  table-layout: fixed;
  @media screen and (max-width: 1202px) {
    overflow-x: hidden;
    table-layout: auto;
  }
  margin-bottom: 0;
  width: 100%;
  white-space: nowrap;

  th,
  td {
    border-top: $table-border-width solid $nightLaino-opacity;
  }

  th.collapsed-row-wrapper,
  .row-separator > td {
    border-top: none;
  }

  tr.new-platform {
    height: 160px;
    td {
      height: 160px;
    }
  }

  td {
    padding: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  > tbody > tr > td {
    // TODO Link it with the Theme system
    color: rgba(255, 255, 255, 0.7) !important;

    .photo {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      overflow: hidden;
      margin: 0 auto;

      img {
        width: 100%;
      }
    }
  }

  > tbody > tr.table-success > td {
    background-color: darken($success, 10%);
  }

  > tbody > tr.table-info > td {
    background-color: $info;
  }

  > tbody > tr.table-primary > td {
    background-color: $primary;
  }

  > tbody > tr.table-warning > td {
    background-color: $warning;
  }
  > tbody > tr.table-danger > td {
    background-color: $danger;
  }

  .img-wrapper {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
  }

  .img-row {
    max-width: 60px;
    width: 60px;
  }

  .form-check {
    margin: 0;
    margin-top: 14px;

    & label .form-check-sign::before,
    & label .form-check-sign::after {
      top: -17px;
      left: 4px;
    }
  }

  .btn {
    margin: 0;
  }

  small,
  .small {
    font-weight: 300;
  }

  .card-tasks .card-body & {
    margin-bottom: 0;

    > thead > tr > th,
    > tbody > tr > th,
    > tfoot > tr > th,
    > thead > tr > td,
    > tbody > tr > td,
    > tfoot > tr > td {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  > thead > tr > th {
    border-bottom-width: 1px;
    font-size: $font-size-base;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    border: 0;
    color: rgba($white, 0.7);
  }

  .radio,
  .checkbox {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    width: 15px;

    .icons {
      position: relative;
    }

    label {
      &:after,
      &:before {
        top: -17px;
        left: -3px;
      }
    }
  }
  > thead > tr > th,
  > tbody > tr > th,
  > tfoot > tr > th,
  > thead > tr > td,
  > tbody > tr > td,
  > tfoot > tr > td {
    padding: 15px;
    vertical-align: middle;
    width: 10%;
  }

  .platform-name-column {
    .form-control {
      margin: 0;
    }
  }

  > tbody > tr > th.collapsed-row-wrapper {
    padding: 0;
    border-top: none;
  }

  .administration-column-set {
    text-overflow: initial;
    text-align: end;
  }

  &.table-shopping tbody tr:last-child td {
    border: none;
  }

  .th-description {
    max-width: 150px;
  }

  .theader {
    th {
      text-align: start;
    }
    :last-child {
      text-align: end;
    }
    .row-1 {
      width: 8%;
    }
    .row-2 {
      width: 15%;
    }
    .row-3 {
      width: 15%;
    }
    .row-4 {
      width: 27%;
    }
    .row-5 {
      width: 10%;
    }
    .row-monitoring {
      text-align: center;
    }
  }

  .service-apps {
    .row-1 {
      width: 30%;
    }
    .row-2 {
      width: 20%;
    }
    .row-3 {
      width: 30%;
    }
    .row-4 {
      width: 20%;
    }
    .row-services {
      text-align: center;
    }
  }

  .table-addons-info,
  .table-status-info {
    .header-status-wrapper {
      display: flex;
      justify-content: space-between;
    }
  }

  .messaging-wrapper {
    td {
      border: none;
    }
    .table-status-info {
      display: flex;
      justify-content: space-between;
      th {
        border-top: none;
        font-weight: $font-weight-bold;
        letter-spacing: 0.04rem;
      }
      .header-status-wrapper {
        padding-top: 0;
        text-align: start;
        &.header-onlineusers {
          text-align: end;
        }
      }
      span {
        font-size: $font-size-sm;
      }
    }
  }

  .td-price {
    font-size: 26px;
    font-weight: $font-weight-light;
    margin-top: 5px;
    position: relative;
    top: 4px;
    text-align: right;
  }

  .td-total {
    font-weight: $font-weight-bold;
    font-size: $h5-font-size;
    padding-top: 20px;
    text-align: right;
  }

  .td-actions .btn {
    margin: 0px;
  }

  > tbody > tr {
    position: relative;
  }

  > tfoot > tr {
    color: hsla(0, 0%, 100%, 0.7);
    text-transform: uppercase;
  }
}

.table-lainotik {
  background-color: $black;
  border: 1px solid $night-laino;
  th,
  td {
    color: rgba($white, 0.7);
  }
  th {
    border-top: none;
    text-align: center;
    text-transform: uppercase;
    padding: 1rem;
    vertical-align: top;
  }
  td {
    border-top: $table-border-width solid $nightLaino-opacity;
    padding: 0.5rem;
  }
  .column-name,
  .column-pod {
    text-align: start;
  }
  .table-value {
    text-align: center;
    &.value-name,
    &.value-label {
      padding-left: 16px;
      text-align: start;
    }

    &.value-label {
      text-decoration: underline;
      &:hover {
        cursor: context-menu;
      }
    }
  }
}

.table-nodes,
.table-storageclass {
  margin-bottom: 20px;
}

.table-nodes {
  .column-name {
    width: 20%;
  }
  .column-label {
    width: 15%;
  }
  .column-podslimit {
    width: 10%;
  }
  .column-cpu {
    width: 10%;
  }
  .column-mem {
    width: 10%;
  }
  .column-percentcpu {
    width: 10%;
  }
  .column-percentmem {
    width: 10%;
  }
  .column-creation {
    width: 15%;
  }
}

.table-storageclass {
  .column-name {
    width: 30%;
  }
  .column-reclaim {
    width: 10%;
  }
  .column-binding {
    width: 15%;
  }
  .column-expansion {
    width: 10%;
  }
  .column-provisioner {
    width: 35%;
  }
}

.table-addons {
  .column-pod {
    width: 25%;
  }
  .column-state {
    width: 25%;
  }
  .column-duration {
    width: 25%;
  }
  .column-date {
    width: 25%;
  }
}

.table-notifications {
  .column-pod {
    width: 35%;
  }
  .column-phase {
    width: 20%;
  }
  .column-sche {
    width: 15%;
  }
  .column-init {
    width: 15%;
  }
  .column-ready {
    width: 15%;
  }
}

.table-notifications,
.table-roles {
  .header-status-wrapper {
    &.header-deploystatus {
      width: 19%;
    }
    &.header-deployowner {
      width: 16%;
    }
    &.header-lastupdate {
      width: 14%;
    }
    &.header-deployduration {
      width: 8%;
    }
    &.header-onlineusers {
      width: 6%;
    }
  }
  .column-header {
    width: 10%;
  }
}

.table-notifications {
  margin: 20px 0;
  .column-name {
    text-align: start;
    width: 30%;
  }
}

[data="primary"] {
  .table-responsive {
    .elem-settings-btn {
      &:hover,
      &.expanded {
        border-color: $primary;
        color: $primary;
      }
    }
    .value-label {
      &:hover {
        color: $primary;
      }
    }
  }
}
[data="second"] {
  .table-responsive {
    .elem-settings-btn {
      &:hover,
      &.expanded {
        border-color: $second;
        color: $second;
      }
    }
    .value-label {
      &:hover {
        color: $second;
      }
    }
  }
}
[data="third"] {
  .table-responsive {
    .elem-settings-btn {
      &:hover,
      &.expanded {
        border-color: $third;
        color: $third;
      }
    }
    .value-label {
      &:hover {
        color: $third;
      }
    }
  }
}

.status-icon {
  background: $warning;
  border: 2px solid $white;
  border-radius: 50%;
  display: inline-block;
  height: 15px;
  width: 15px;
  margin-left: 15px;

  &.running {
    background: $success;
  }
  &.notrunning {
    background: $danger;
  }
  &:hover {
    cursor: pointer;
  }
}

.table-responsive {
  //overflow: scroll;
  .wrapper-monitoring {
    text-align: center;
    .pre-create-msg-wrapper,
    .monitoring-msg-wrapper {
      margin-top: 30px;
      text-align: left;
      .form-group {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
      }
      i {
        font-size: $font-size-xl;
        margin-right: 10px;
      }
    }
    .pre-create-msg-wrapper {
      label {
        &:hover {
          cursor: pointer;
        }
      }
    }
    .monitoring-msg-wrapper {
      p {
        min-height: 50px;
        text-align: center;
      }
    }
  }

  .elem-settings-btn {
    border: 2px solid rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    margin: 0 10px;
    padding: 5px;
    font-size: 20px;
    &:hover {
      cursor: pointer;
    }
  }

  .wrapper-config-btn {
    vertical-align: bottom;
  }

  .collapsed-content {
    border: none;
    padding: 0 0 30px 0;
    label {
      min-width: 90px;
    }
  }
}

#tables .table-responsive {
  margin-bottom: 30px;
}
