/* Login Form */
.signup-form-wrapper,
.login-form-wrapper,
.invite-email-wrapper {
  label,
  span {
    color: rgba(255, 255, 255, 0.7);
  }

  .login-form,
  .resetpass-form {
    margin: 20px 0;
  }
}

/* Signup Form */
.signup-form-wrapper,
.invite-email-wrapper {
  h1 {
    font-size: 55px;
  }
  .signup-confirmation {
    line-height: 2.3rem;
    .signup-email {
      color: white;
      font-weight: bold;
      padding: 0 8px;
    }
  }
  .section-intro {
    p {
      line-height: 1.7rem;
      margin: 18px 0;
    }
  }
  .signup-link {
    display: block;
    text-align: center;
  }
}
/* Form controls */
@include form-control-placeholder(#6c757c, 1);

[data="primary"] {
  .form-control {
    &:focus {
      border-color: $primary;
      & + .input-group-append .input-group-text,
      & ~ .input-group-append .input-group-text,
      & + .input-group-prepend .input-group-text,
      & ~ .input-group-prepend .input-group-text {
        border: 1px solid $primary;
      }
    }
  }
  .app-icon {
    &:hover {
      color: $primary;
    }
  }
  .wrapper-subsection-control .icon-copy:hover {
    color: $primary;
  }
}
[data="second"] {
  .form-control {
    &:focus {
      border-color: $second;
      & + .input-group-append .input-group-text,
      & ~ .input-group-append .input-group-text,
      & + .input-group-prepend .input-group-text,
      & ~ .input-group-prepend .input-group-text {
        border: 1px solid $second;
      }
    }
  }
  .app-icon {
    &:hover {
      color: $second;
    }
  }
  .wrapper-subsection-control .icon-copy:hover {
    color: $second;
  }
}
[data="third"] {
  .form-control {
    &:focus {
      border-color: $third;
      & + .input-group-append .input-group-text,
      & ~ .input-group-append .input-group-text,
      & + .input-group-prepend .input-group-text,
      & ~ .input-group-prepend .input-group-text {
        border: 1px solid $third;
      }
    }
  }
  .app-icon {
    &:hover {
      color: $third;
    }
  }
  .wrapper-subsection-control .icon-copy:hover {
    color: $third;
  }
}

.form-control {
  border-color: lighten($black, 5%);
  border-radius: $border-radius-lg;
  font-size: $font-size-sm;
  height: calc(2.25rem + 3px);
  margin-bottom: $input-margin-vertical;
  @include transition-input-focus-color();

  &.dropdown-wrapper {
    &:hover {
      cursor: pointer;
    }
  }

  &:focus {
    background-color: $input-bg;
    @include box-shadow(none);

    & + .input-group-append .input-group-text,
    & ~ .input-group-append .input-group-text,
    & + .input-group-prepend .input-group-text,
    & ~ .input-group-prepend .input-group-text {
      border-left: none;
      background-color: $transparent-bg;
    }
  }

  .has-success &,
  .has-error &,
  .has-success &:focus,
  .has-error &:focus {
    @include box-shadow(none);
  }

  .has-danger &,
  .has-success & {
    &.form-control-success,
    &.form-control-danger {
      background-image: none;
    }
  }

  & + .form-control-feedback {
    border-radius: $border-radius-lg;
    margin-top: -7px;
    position: absolute;
    right: 10px;
    top: 50%;
    vertical-align: middle;
  }

  .open & {
    border-radius: $border-radius-lg $border-radius-lg 0 0;
    border-bottom-color: $transparent-bg;
  }

  & + .input-group-append .input-group-text,
  & + .input-group-prepend .input-group-text {
    background-color: $white;
  }
}

.platform-name-column {
  .form-group,
  .form-control {
    margin: 0;
  }
}

.has-success .input-group-append .input-group-text,
.has-success .input-group-prepend .input-group-text,
.has-success .form-control {
  border-color: lighten($black, 5%);
}

.has-success .form-control:focus,
.has-success.input-group-focus .input-group-append .input-group-text,
.has-success.input-group-focus .input-group-prepend .input-group-text {
  border-color: darken($success, 10%);
}

.has-danger .form-control,
.has-danger .input-group-append .input-group-text,
.has-danger .input-group-prepend .input-group-text,
.has-danger.input-group-focus .input-group-prepend .input-group-text,
.has-danger.input-group-focus .input-group-append .input-group-text {
  border-color: lighten($danger-states, 5%);
  color: $danger-states;
  background-color: rgba(222, 222, 222, 0.1);

  &:focus {
    background-color: $transparent-bg;
  }
}

.has-success,
.has-danger {
  &:after {
    font-family: "nucleo";
    content: "\ea1b";
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 13px;
    color: $success;
    font-size: 11px;
  }

  &.form-control-lg {
    &:after {
      font-size: 13px;
      top: 24px;
    }
  }

  &.has-label {
    &:after {
      top: 37px;
    }
  }

  &.form-check:after {
    display: none !important;
  }

  &.form-check .form-check-label {
    color: $success;
  }
}

.has-danger {
  &:after {
    content: "\ea48";
    color: $danger-states;
  }

  &.form-check .form-check-label {
    color: $danger-states;
  }
}

.elem-submit-btn {
  margin: 30px 0;
}

.app-icon {
  font-size: 40px;
}

.row-separator {
  td {
    border: none;
  }

  &.messaging-wrapper {
    td {
      padding: 0;
    }
  }
}

.text-primary {
  th {
    text-align: center;
  }
}
.collapsed-content {
  .form-wrapper {
    .form-group {
      margin-bottom: 0;
      .component-row {
        align-items: flex-end;

        .settings-wrapper {
          display: flex;
          flex-direction: column;
          margin: 12px 0;
          .settings-elem {
            display: flex;
            flex-direction: column;
            input {
              border-radius: $border-radius-lg;
              width: 100%;
            }
          }
          .switch {
            display: block;
            width: min-content;
          }
        }
      }
    }
  }
}

.highlighted-subsection {
  background-color: $black;
  border: 1px solid $night-laino;
  padding: 12px;
  margin: 12px 0;
}

.invalid-feedback {
  white-space: normal;
}
