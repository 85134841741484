//
// Rotating border
//
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

//
// Growing circle
//

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-overlay {
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  left: 0;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  transition: 0.5s;
  width: 100%;
  z-index: 9999;

  .laino-spinner {
    position: absolute;
    top: 50%;
    left: 50%;

    &.spinner-border {
      display: inline-block;
      width: $spinner-width;
      height: $spinner-height;
      vertical-align: text-bottom;
      border: $spinner-border-width solid currentColor;
      border-right-color: transparent;
      // stylelint-disable-next-line property-disallowed-list
      border-radius: 50%;
      animation: 0.75s linear infinite spinner-border;
    }

    &.spinner-border-sm {
      width: $spinner-width-sm;
      height: $spinner-height-sm;
      border-width: $spinner-border-width-sm;
    }

    &.spinner-grow {
      display: inline-block;
      width: 3rem;
      height: 3rem;
      vertical-align: text-bottom;
      background-color: currentColor;
      // stylelint-disable-next-line property-disallowed-list
      border-radius: 50%;
      opacity: 0;
      animation: 0.75s linear infinite spinner-grow;
    }

    &.spinner-grow-sm {
      width: $spinner-width-sm;
      height: $spinner-height-sm;
    }

    @if $enable-prefers-reduced-motion-media-query {
      @media (prefers-reduced-motion: reduce) {
        .spinner-border,
        .spinner-grow {
          animation-duration: 1.5s;
        }
      }
    }
  }
}
