@mixin badge-variant($bg) {
  color: color-yiq($bg);
  background-color: $bg;

  &[href] {
    @include hover-focus {
      color: color-yiq($bg);
      text-decoration: none;
      background-color: darken($bg, 3%);
    }
  }
  .tagsinput-remove-link{
      color: $white;
  }
}
