.login-form-wrapper,
.signup-form-wrapper {
  .footer {
    position: relative;
  }
}

.footer {
  flex-grow: 1;

  [class*="container-"] {
    padding: 0;
  }

  .original-footer {
    justify-content: space-between;
    @media screen and (min-width: 1025px) {
      margin-left: 308px;
      width: calc(100% - 338px);
    }
  }
  .extended-footer {
    justify-content: center;
    margin-top: 30px;
    .font-license {
      font-size: 0.75rem;
    }
  }

  .nav {
    display: inline-block;
    list-style: none;
    margin-bottom: 0;
  }

  .nav-item {
    display: inline-block;

    &:first-child a {
      padding-left: 0;
    }
  }

  .nav-link {
    color: $white;
    padding: 0 $padding-base-vertical;
    font-size: $font-size-sm;
    text-transform: uppercase;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  .copyright {
    font-size: $font-size-sm;
    line-height: 1.8;
    color: $white;
  }

  &:after {
    display: table;
    clear: both;
    content: " ";
  }
}

@media screen and (max-width: 1024px) {
  .footer {
    padding: 0 24px;

    .copyright {
      text-align: right;
    }
  }
}

@media screen and (min-width: 1025px) {
  .footer {
    .copyright {
      float: right;
    }
  }
}

@media screen and (max-width: 768px) {
  .footer {
    nav {
      display: block;
      margin-bottom: 5px;
      float: none;
    }
  }
}

@media screen and (max-width: 576px) {
  .footer {
    text-align: center;
    .copyright {
      text-align: center;
    }

    .nav {
      float: none;
      padding-left: 0;
    }
  }
}
