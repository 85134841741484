[data="primary"] {
  .card-user .author .block.block-one {
    @include linear-gradient-right(rgba($primary, 0.6), rgba($primary, 0));
  }
}
[data="second"] {
  .card-user .author .block.block-one {
    @include linear-gradient-right(rgba($second, 0.6), rgba($second, 0));
  }
}
[data="third"] {
  .card-user .author .block.block-one {
    @include linear-gradient-right(rgba($third, 0.6), rgba($third, 0));
  }
}

.card-user {
  padding-top: 15px;
  overflow: hidden;
  .image {
    height: 120px;
  }

  .author {
    text-align: center;
    text-transform: none;

    a + p.description {
      margin-top: -7px;
    }

    .block {
      position: absolute;
      height: 100px;
      width: 100%;
      &.block-one {
        @include nc-rotate(0deg, 10);
        margin-top: -40px;
        margin-left: -15px;
      }
    }
    .title,
    .role {
      text-align: left;
    }

    .title {
      color: $white;
      font-size: 25px;
      margin-bottom: 0;
      margin-top: 20px;
    }

    .role {
      margin-top: 20px;
    }
  }

  .avatar {
    width: 124px;
    height: 124px;
    line-height: 124px;  
    font-size: 60px;
  }

  hr {
    margin: 5px 15px;
  }

  .button-container {
    margin-bottom: 6px;
    text-align: center;
  }
}
