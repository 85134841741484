.container {
  display: flex;
  flex-direction: column;
  .content {
    margin: 50px 0;
  }
  
  .signup-form-wrapper {
    .section-header,
    .card {
      max-width: 750px;
    }
    .card {
      margin-top: 20px;
      padding: 20px;
    }
  }
  
  .login-form-wrapper {
    margin-top: 50px;
  }
  
  .login-form-wrapper,
  .signup-form-wrapper,
  .invite-email-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .copyright {
      bottom: 20px;
      position: absolute;
      right: 0;
    }
    .onboarding-nav-link {
      display: inline-block;
      margin-top: 20px;
    }
  }
  .section-title.invite-email-title {
    margin-top: 60px;
  }
}

// Special alignments
  
.block-vert-align-middle {
  align-self: center;
}

.align-right {
  float: right;
}