img{
    max-width: 100%;
    border-radius: $border-radius-sm;
}
.img-raised{
    box-shadow: $box-shadow-raised;
}

//Login page logo
.lainotik-logo {
    display: block;
    margin: 0 auto;
    width: 60%;
}

//SignUp page variation
.signup-form-wrapper {
    .lainotik-logo {
        width: 125px;
    }
}
