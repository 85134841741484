.tooltip {
  .tooltip-inner {
    text-align: left;

    ul {
      list-style: none;
      margin: 0;
      padding: 5px;
      li {
        color: $black-states;
          
        .tooltip-label {
          font-weight: $font-weight-bold;
        }
      }
    }
  }
}