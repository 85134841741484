.fixed-plugin{
    position: fixed;
    right: 0;
    width: 64px;
    background: rgba(0,0,0,.3);
    z-index: 1031;
    border-radius: 8px 0 0 8px;
    text-align: center;
    top: 130px;

    li > a,
    .badge{
        transition: all .34s;
        -webkit-transition: all .34s;
        -moz-transition: all .34s;
    }

    .fa-cog{
        color: $white;
        padding: 10px;
        border-radius: 0 0 6px 6px;
        width: auto;
    }

    .dropdown-menu{
        right: 80px;
        left: auto !important;
        top: -52px !important;
        width: 290px;
        border-radius: 0.1875rem;
        padding: 0 10px;
        background: linear-gradient($black, $black-states);
    }

    .dropdown .dropdown-menu {
      top: 5px;
    }

    .dropdown-menu:after,
    .dropdown-menu:before{
        right: 10px;
        margin-left: auto;
        left: auto;
    }

    .fa-circle-thin{
        color: $white;
    }

    .active .fa-circle-thin{
        color: $active-blue;
    }

    .dropdown-menu > .active > a,
    .dropdown-menu > .active > a:hover,
    .dropdown-menu > .active > a:focus{
        color: $active-gray;
        text-align: center;
    }

    img{
        border-radius: 0;
        width: 100%;
        height: 100px;
        margin: 0 auto;
    }

    .dropdown-menu li > a:hover,
    .dropdown-menu li > a:focus{
        box-shadow: none;
    }

    .badge{
        border: 2px solid $white;
        border-radius: 50%;
        cursor: pointer;
        display: inline-block;
        height: 23px;
        margin-right: 5px;
        position: relative;
        width: 23px;
    }

    .badge.active,
    .badge:hover{
        border-color: $black-states;
    }

    .light-badge, .dark-badge{
      margin:0;
      border: 1px solid $info;
      &:hover{
        border: 1px solid $info;
      }
    }

    .light-badge{
      background: $white;
      &:hover{
        background: $white;
      }
    }

    .dark-badge{
      background: $black;
      &:hover{
        background: $black;
      }
    }

    h5{
      margin: 10px;
    }

    .dropdown-menu li{
        display: block;
        padding: 18px 2px;
        width: 25%;
        float: left;
    }

    li.adjustments-line,
    li.header-title,
    li.button-container{
        width: 100%;
        height: 50px;
        min-height: inherit;
    }

    li.button-container{
        height: auto;

        div{
            margin-bottom: 5px;
        }
    }

    #sharrreTitle{
        text-align: center;
        padding: 10px 0;
        height: 50px;
    }

    li.header-title{
        color: $white;
        height: 30px;
        line-height: 25px;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;
    }

    .adjustments-line{

        a{
            color: transparent;

            .badge-colors{
                position: relative;
                top: -2px;
            }

            a:hover,
            a:focus{
                color: transparent;
            }
        }

        .togglebutton{
            text-align: center;

            .label-switch{
              position: relative;
              left: -10px;
              font-size: $font-size-xs;
              color: $white;

              &.label-right{
                left: 10px;
              }
            }

            .toggle{
                margin-right: 0;
            }
        }

        .color-label{
          position: relative;
          top: -7px;
          font-size: $font-size-xs;
          color: $white;
        }

        .dropdown-menu > li.adjustments-line > a{
              padding-right: 0;
              padding-left: 0;
              border-bottom: 1px solid #ddd;
              border-radius: 0;
              margin: 0;
        }
    }



    .dropdown-menu{
        > li{
            & > a.img-holder{
                  font-size: $font-paragraph;
                  text-align: center;
                  border-radius: 10px;
                  background-color: $white;
                  border: 3px solid $white;
                  padding-left: 0;
                  padding-right: 0;
                  opacity: 1;
                  cursor: pointer;
                  display: block;
                  max-height: 100px;
                  overflow: hidden;
                  padding: 0;

                  img{
                     margin-top: auto;
                  }
            }

            a.switch-trigger:hover,
            & > a.switch-trigger:focus{
                background-color: transparent;
            }

            &:hover,
            &:focus{
                > a.img-holder{
                    border-color: rgba(0, 187, 255, 0.53);
                }
            }
        }

        > .active > a.img-holder,
        > .active > a.img-holder{
            border-color: $active-blue;
            background-color: $white;
        }

    }

    .btn-social{
        width: 50%;
        display: block;
        width: 48%;
        float: left;
        font-weight: 600;
    }

    .btn-social{
        i{
            margin-right: 5px;
        }

        &:first-child{
            margin-right: 2%;
        }
    }

    .dropdown{
        i {
            font-size: $font-size-xxl;
            &:hover {
                color: $white;
                cursor: pointer;
            }
        }
        .dropdown-menu{
             -webkit-transform: translateY(-15%);
             -moz-transform: translateY(-15%);
             -o-transform: translateY(-15%);
             -ms-transform: translateY(-15%);
             transform: translateY(-15%);
             top: 27px;
             opacity: 0;

             transform-origin: 0 0;

             &:before{
                 border-bottom: .4em solid rgba(0, 0, 0, 0);
                 border-left: .4em solid rgba(0,0,0,0.2);
                 border-top: .4em solid rgba(0,0,0,0);
                 right: -16px;
                 top: 46px;
             }

             &:after{
                 border-bottom: .4em solid rgba(0, 0, 0, 0);
                 border-left: .4em solid $black;
                 border-top: .4em solid rgba(0,0,0,0);
                 right: -16px;
             }

             &:before,
             &:after{
                 content: "";
                 display: inline-block;
                 position: absolute;
                 top: 74px;
                 width: 16px;
                 transform: translateY(-50%);
                 -webkit-transform: translateY(-50%);
                 -moz-transform: translateY(-50%);
             }
        }
        &.show i {
            color: $white;
        }

        &.show .dropdown-menu{
             opacity: 1;

             -webkit-transform: translateY(-13%);
             -moz-transform: translateY(-13%);
             -o-transform: translateY(-13%);
             -ms-transform: translateY(-13%);
             transform: translateY(-13%);

             transform-origin: 0 0;
        }
    }

    .bootstrap-switch{
        margin:0;
    }
}
