.white-content {
  background: $light-bg;

  .navbar.navbar-transparent .navbar-brand {
    color: $black-states;
  }

  .navbar.navbar-transparent .navbar-toggler-bar {
    background: $black-states;
  }
  .navbar.navbar-transparent .navbar-nav li a:not(.dropdown-item),
  .lang-select-dropdown-wrapper {
    color: $black-states;
    & i {
      color: $black-states;
    }
  }

  .navbar.navbar-transparent .navbar-minimize button i {
    color: $black-states;
  }

  .navbar.navbar-transparent .search-bar.input-group i {
    color: $black-states;
  }
  .navbar.navbar-transparent .search-bar.input-group .form-control {
    color: $default;
    &::placeholder {
      color: $dark-gray;
    }
  }

  .sidebar {
    box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.1),
      0 4px 20px 0 rgba(0, 0, 0, 0.15);
    p {
      color: $opacity-8;
    }
    ul {
      li {
        color: $white;
      }
    }
  }

  .main-panel {
    background: $light-bg;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ol li,
  ul li,
  pre {
    color: $black-states;
  }

  label,
  label:not(.btn),
  .signup-form-wrapper label {
    color: $default;
  }

  .font-icon-detail i {
    color: $black;
  }

  .status-icon {
    border: 2px solid $black-states;
  }

  .custom-control-input:checked ~ .custom-control-label {
    &::after {
      background-image: $custom-radio-indicator-icon-checked-dark;
    }
  }

  .custom-checkbox-button-wrapper {
    .custom-control-input:checked ~ .custom-control-label {
      &::after {
        background-image: $custom-checkbox-indicator-icon-checked-dark;
      }
    }
  }

  [data="primary"] {
    .input-group-focus {
      .input-group-prepend .input-group-text,
      .input-group-append .input-group-text,
      .form-control {
        border-color: $primary;
      }
    }
    .form-control {
      &:focus {
        border-color: $primary;
      }
    }
    .table-responsive {
      .elem-settings-btn {
        &:hover {
          border-color: $primary;
          color: $primary;
        }
        &.expanded {
          border-color: $primary;
          color: $primary;
        }
      }
    }
    .cluster-icon {
      &:hover {
        i {
          border-color: $primary;
          color: $primary;
        }
      }
    }
  }
  [data="second"] {
    .input-group-focus {
      .input-group-prepend .input-group-text,
      .input-group-append .input-group-text,
      .form-control {
        border-color: $second;
      }
    }
    .form-control {
      &:focus {
        border-color: $second;
      }
    }
    .table-responsive {
      .elem-settings-btn {
        &:hover {
          border-color: $second;
          color: $second;
        }
        &.expanded {
          border-color: $second;
          color: $second;
        }
      }
    }
    .cluster-icon {
      &:hover {
        i {
          border-color: $second;
          color: $second;
        }
      }
    }
  }
  [data="third"] {
    .input-group-focus {
      .input-group-prepend .input-group-text,
      .input-group-append .input-group-text,
      .form-control {
        border-color: $third;
      }
    }
    .form-control {
      &:focus {
        border-color: $third;
      }
    }
    .table-responsive {
      .elem-settings-btn {
        &:hover {
          border-color: $third;
          color: $third;
        }
        &.expanded {
          border-color: $third;
          color: $third;
        }
      }
    }
    .cluster-icon {
      &:hover {
        i {
          border-color: $third;
          color: $third;
        }
      }
    }
  }

  .cluster-icon {
    &.link-disabled i {
      color: rgba(34, 42, 66, 0.3);
    }

    i {
      color: rgba(0, 0, 0, 0.7);
    }
  }

  .btn:not([data-action]):hover {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  }

  .btn-neutral.btn-link {
    color: rgba($black, 0.7);
  }

  .overview-wrapper {
    .setup-separator {
      border-color: rgba(0, 0, 0, 0.7);
    }
    .step-name {
      font-weight: $font-weight-bold;
    }
  }

  .settings-section {
    &.sub-section {
      .sub-section-header {
        border-color: rgba(0, 0, 0, 0.2);
      }
    }
  }

  .section-wrapper {
    &.highlighted-section {
      .highlighted-text {
        color: $black-states;
      }
    }
  }

  .highlighted-section {
    h5 {
      color: $black-states;
    }
  }

  .subsection-title {
    color: $black-states;
  }

  .settings-conf-list {
    .component-config-wrapper {
      .settings-conf-elem {
        border-bottom-color: $black-states;
        &.info-wrapper.btn {
          color: $black-states;
        }
      }
    }
  }

  //style for inputs

  @include form-control-placeholder(rgba($black, 0.4), 1);
  .has-danger {
    .form-control,
    .input-group-prepend .input-group-text {
      border-color: $danger-states;
    }
  }

  .input-group-prepend .input-group-text {
    border-color: rgba($black-states, 0.5);
    color: $black-states;
  }

  .form-control {
    color: $black;
    border-color: rgba($black-states, 0.5);
  }

  .form-group.no-border,
  .input-group.no-border {
    .form-control,
    .form-control + .input-group-prepend .input-group-text,
    .form-control + .input-group-append .input-group-text,
    .input-group-prepend .input-group-text,
    .input-group-append .input-group-text {
      background-color: $opacity-gray-3;
      &:focus,
      &:active,
      &:active {
        background-color: $opacity-gray-5;
      }
    }

    .form-control {
      &:focus {
        & + .input-group-prepend .input-group-text,
        & + .input-group-append .input-group-text {
          background-color: $transparent-bg;
        }
      }
    }
  }

  .input-group[disabled] {
    .input-group-prepend .input-group-text,
    .input-group-append .input-group-text {
      background-color: $black;
    }
  }

  .form-control[disabled],
  .form-control[readonly] {
    background: $light-gray;
    border-color: rgba($black-states, 0.3);
  }

  fieldset[disabled].custom-checkbox-button-wrapper .form-control {
    background-color: transparent;
  }

  .platform-name-column {
    .form-group {
      .form-control[disabled] {
        background: none;
        color: $black-states;
      }
    }
  }

  .input-group-focus {
    &.no-border {
      .input-group-prepend .input-group-text,
      .input-group-append .input-group-text {
        background-color: $opacity-gray-5;
      }
    }
  }
  .input-group-prepend .input-group-text {
    border-right: none;
  }

  .input-group-append .input-group-text {
    border-left: none;
  }

  .has-danger .form-control:focus,
  .has-success.input-group-focus .input-group-append .input-group-text,
  .has-success.input-group-focus .input-group-prepend .input-group-text {
    border-color: $danger-states;
  }

  .has-success .form-control:focus,
  .has-success.input-group-focus .input-group-append .input-group-text,
  .has-success.input-group-focus .input-group-prepend .input-group-text {
    border-color: darken($success, 10%);
  }

  //Info styled button - icon + text
  .info-wrapper {
    &.disabled,
    &[disabled] {
      color: $gray-900;
    }
  }

  .btn.btn-link {
    &:hover,
    &:active,
    &:focus {
      color: $dark-gray !important;
    }
  }

  .btn-group .btn.active {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  }

  .react-switch-handle {
    background: $black-states !important;
  }

  .card {
    h5 {
      border-color: $dayLaino-opacity;
    }
    &.card-user {
      .author .title {
        color: $black-states;
      }
    }
  }

  .card:not(.card-white) {
    background: $white;
    box-shadow: 0 1px 15px 0 rgba(123, 123, 123, 0.05);
    .card-header {
      color: $black;
      a[data-toggle="collapse"] {
        color: $black;
      }
    }
    .card-header .card-title,
    .card-body .card-title {
      color: $black-states;
    }

    .card-body {
      .card-category,
      .card-description {
        color: $black-states;
      }
    }

    &.nav-pills .nav-item .nav-link {
      color: $nav-gray;
      background-color: hsla(0, 0%, 87%, 0.3);
      &:not(.active):hover {
        background: hsla(0, 0%, 87%, 0.5);
      }
      &.active {
        color: $white;
      }
    }

    .tab-content .tab-pane {
      color: $nav-gray;
    }

    .card {
      box-shadow: none;
    }

    &.card-plain {
      background: $transparent-bg;
      box-shadow: none;
    }

    &.card-tasks {
      .card-body {
        i {
          color: rgba(34, 42, 66, 0.7);
          &:hover {
            color: $black;
          }
        }
      }
    }
  }

  // Style for tooltips - Based on Reactstrap library component
  .tooltip {
    .tooltip-inner {
      color: $white;
      background-color: $card-black-background;

      ul {
        li {
          color: $white;
        }
      }
    }
  }

  .table,
  table {
    > tbody > tr > td,
    > tbody > tr > td > table > th > span {
      color: rgba($black, 0.7) !important;
    }

    th,
    td {
      border-color: $dayLaino-opacity;
      color: rgba($black, 0.7);
    }

    > tbody > tr > .app-name {
      color: $black-states !important;
    }

    .app-status-label,
    .addon-status-label,
    .app-status-msg {
      color: rgba($black, 0.7) !important;
    }

    .addon-status-label {
      .addon-msg-state {
        font-weight: $font-weight-bold;
      }
    }

    > thead > tr > th,
    button.btn-neutral.btn-link {
      color: rgba($black, 0.7);
    }

    //TODO review this style assignment
    .table-nodes,
    .table-addons,
    .table-storageclass,
    .table-monitoring,
    .table-notifications,
    &.table-roles {
      background-color: #fafafa;
      border-color: $day-laino;
    }

    &.table-roles {
      .column-header,
      .notif-table-value {
        color: rgba($black, 0.6);
      }
    }
  }

  .table-responsive {
    .elem-settings-btn {
      border-color: rgba(0, 0, 0, 0.7);
      &:disabled,
      &[disabled],
      &.disabled {
        color: rgba($black, 0.7);
        @include opacity(0.5);
        pointer-events: none;
      }
    }
  }

  .footer ul li a {
    color: $black-states;
  }

  .footer .copyright {
    color: $black-states;
  }

  .progress-container,
  .progress-container.progress.sm {
    .progress {
      background: rgba($black, 0.1);
      box-shadow: 0 0 0 3px rgba($black, 0.1);
      .progress-value {
        color: $default;
      }
    }

    .progress-badge {
      color: $black-states;
    }
  }

  .full-page {
    background: $light-bg;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ol li,
    ul li,
    pre {
      color: $black-states;
    }

    .description {
      color: $dark-gray;
    }

    .footer ul li a {
      color: $black-states;
    }

    .footer .copyright {
      color: $black-states;
    }
  }

  .nav-pills .nav-item .nav-link:not(.active) {
    background: darken($light-bg, 10%);
    color: $black-states;
    &:hover {
      background: darken($white, 20%);
    }
  }

  .highlighted-subsection {
    background-color: #fafafa;
    border-color: $day-laino;
  }
}
