@mixin icon-shape-variant($color) {
  color: saturate(darken($color, 10%), 10);
  background-color: transparentize(lighten($color, 10%), 0.5);
}

.icon-info,
.icon-alert {
  font-size: 15px;
  font-weight: $font-weight-bold !important;
  margin: 0 8px 0 5px;
  &.info-context {
    color: $info;
  }
}

.icon-info {
  border-radius: 50%;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.icon-alert {
  color: $warning;
}

//Info, Warning and Danger colouring
.icon-info,
.icon-warning,
.icon-alert {
  &.info {
    color: $info;
  }
  &.success {
    color: $success;
  }
  &.warning {
    color: $warning;
  }
  &.danger {
    color: $danger;
  }
}
