.custom-range[type="range"] {
  //Chrome
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    overflow: hidden;
    -webkit-appearance: none;
    height: 10px;
    &::-webkit-slider-runnable-track {
      -webkit-appearance: none;
      margin-top: -1px;
      margin-left: -1px;
    }

    &::-webkit-slider-thumb {
      width: 12px;
      height: 18px;
      -webkit-appearance: none;
      @include gradient-bg($black-states);
      &:active {
        @include gradient-bg($custom-range-thumb-active-bg);
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  //FF
  &::-moz-range-progress {
    height: 10px;
  }
  &::-moz-range-track {
    background-color: gradient-bg($custom-range-thumb-active-bg);
  }
  &::-moz-range-thumb {
    @include gradient-bg($black-states);
  }
  //IE
  &::-ms-fill-lower {
    height: 10px;
  }
  &::-ms-fill-upper {
    background-color: gradient-bg($custom-range-thumb-active-bg);
  }
}

.custom-radio-button-wrapper {
  legend {
    font-size: 0.75rem;
    margin: 0 0 15px 15px;
  }

  .form-control {
    display: flex;
    flex-wrap: wrap;
    .custom-control-inline {
      padding-top: 12px;
    }
  }

  .custom-control-input:checked ~ .custom-control-label {
    font-weight: $font-weight-bold;
  }
}

.custom-checkbox {
  label.custom-control-label {
    align-items: flex-end;
    display: flex;
    margin: 0;
    &:hover {
      cursor: pointer;
    }
  }
}

.wrapper-monitoring {
  .custom-control-label {
    &::before,
    &::after {
      top: -0.77875rem;
    }
  }
  .form-check-label {
    margin-bottom: 0;
  }
  .check-input-ctrl.custom-checkbox.custom-control {
    min-height: auto;
  }
}

[data="primary"] {
  .custom-range[type="range"] {
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
      &::-webkit-slider-runnable-track {
        color: $primary;
      }
      &::-webkit-slider-thumb {
        box-shadow: -200px 0 0 200px $primary;
      }
    }
    &::-moz-range-progress,
    &::-ms-fill-lower {
      background-color: $primary;
    }
  }
  .custom-control-input {
    &:checked ~ .custom-control-label::before {
      background-color: $primary;
      color: $primary;
    }
  }
  .custom-control-label {
    &::before {
      background-color: $primary;
    }
  }
}
[data="second"] {
  .custom-range[type="range"] {
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
      &::-webkit-slider-runnable-track {
        color: $second;
      }
      &::-webkit-slider-thumb {
        box-shadow: -200px 0 0 200px $second;
      }
    }
    &::-moz-range-progress,
    &::-ms-fill-lower {
      background-color: $second;
    }
  }
  .custom-control-input {
    &:checked ~ .custom-control-label::before {
      background-color: $second;
      color: $second;
    }
  }
  .custom-control-label {
    &::before {
      background-color: $second;
    }
  }
}
[data="third"] {
  .custom-range[type="range"] {
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
      &::-webkit-slider-runnable-track {
        color: $third;
      }
      &::-webkit-slider-thumb {
        box-shadow: -200px 0 0 200px $third;
      }
    }
    &::-moz-range-progress,
    &::-ms-fill-lower {
      background-color: $third;
    }
  }
  .custom-control-input {
    &:checked ~ .custom-control-label::before {
      background-color: $third;
      color: $third;
    }
  }
  .custom-control-label {
    &::before {
      background-color: $third;
    }
  }
}

.range-textbox {
  margin-top: 15px;
}

//Along with a JS
// https://codepen.io/thebabydino/pen/goYYrN
// https://css-tricks.com/sliding-nightmare-understanding-range-input/
