.rtl {
  .sidebar,
  .bootstrap-navbar{
    right: 0;
    left: auto;
    margin-right: 20px;
    margin-left: 0;

    .nav{
        i{
          float: right;
          margin-left: 15px;
          margin-right: 0;
        }

        p{
          margin-right: 45px;
          text-align: right;
        }

        .caret{
          left: 11px;
          right: auto;
        }
    }

    .logo{
        a.logo-mini{
          float: right;
          margin-right: 20px;
          margin-left: 10px;
        }

        .simple-text{
          text-align: right;
        }
    }

    .sidebar-wrapper .nav [data-toggle="collapse"] ~ div > ul > li > a .sidebar-mini-icon,
    .sidebar-wrapper .user .info [data-toggle="collapse"] ~ div > ul > li > a .sidebar-mini-icon{
      float: right;
      margin-left: 15px;
      margin-right: 0;
    }

    .sidebar-wrapper > .nav [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal,
    .sidebar-wrapper .user .info [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal{
      text-align: right;
    }

    &:before{
      right: 30px;
      left: auto;
    }
  }

  .main-panel .content{
    padding: 80px 280px 30px 30px;
  }

  .footer{
    padding: 24px 300px 24px 0;
  }

  .dropdown-toggle:after{
    margin-right: .255em;
    margin-left: 0;
  }

  .dropdown-menu.dropdown-menu-right.dropdown-navbar{
    right: -220px !important;
    left: auto;

    &:before{
      right: auto;
      left: 35px;
    }
  }

  .notification{
    left: 40px;
    right: auto;
  }

  .dropdown-menu{
    right: auto;
    left: 0;
  }


  .minimize-sidebar{
    float: right;
  }

  .alert{
    left: 0;
    margin-left: 0;
    margin-right: 0;
      button.close{
        left: 10px !important;
        right: auto !important;
      }

      span[data-notify="icon"]{
        right: 15px;
        left: auto;
      }



      &.alert-with-icon{
        padding-right: 65px;
        padding-left: 15px;
      }

      &.alert-with-icon i[data-notify="icon"]{
        right: 15px;
        left: auto;
      }
  }

  .search-bar{
    margin-left: 0;
  }

  .modal-search .modal-header .close{
    margin-right: auto;
    left: 10px;
  }


  @media (min-width: 1024px){
    &.sidebar-mini .main-panel .content {
      padding-right: 130px;
      padding-left: 50px;
    }

    &.sidebar-mini footer{
      padding-right: 130px;
      padding-left: 50px;
    }

    .navbar-minimize button{
      margin-right: -5px;
    }
  }



  @media screen and (max-width: 1024px){

    .sidebar{
      margin-right: 0;
    }
    .main-panel .content{
      padding-right: 50px;
    }

    #bodyClick{
      right: 260px;
      left: auto;
    }

    .footer{
      padding-right: 15px;
    }
  }

  .navbar {
    .navbar-nav {
      padding-right: 0;
      a.nav-link {
        text-align: right;
        p{
          margin-right: 7px;
        }
      }

      .btn {
        margin-right: 0;
        padding: 0;
        i{
          margin-left: 4px;
          margin-right: 5px;
        }
      }

      .search-bar span{
        margin-right: 10px;
      }
    }
  }

  .ps__rail-y {
    right: auto !important;
    left: 0;
  }

  .main-panel {
    position: fixed;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .fixed-plugin {
    border-radius: 0 8px 8px 0;
    left: 0;
    right: unset;
    .dropdown-menu {
      left: 80px !important;
      right: auto !important;
      &:before,&:after {
        left: 10px;
        margin-right: auto;
        right: auto;
      }
      &:before {
        right: unset;
        left: -16px;
        border-left: unset;
        border-right: .4em solid rgba(0,0,0,.2);
      }
      &:after {
        right: unset;
        left: -16px;
        border-left: unset;
        border-right: .4em solid $black;
      }
    }
  }
  .sidebar .nav li.active > a:not([data-toggle="collapse"]):before, .off-canvas-sidebar .nav li.active > a:not([data-toggle="collapse"]):before{
    left: unset;
    right: -4px;
  }
}


@media screen and (max-width: 768px){

  .rtl .main-panel .content{
    padding-left: 15px;
    padding-right: 15px;
  }

}
